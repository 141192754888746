import { LogoutOutlined, RedoOutlined, UserOutlined } from '@ant-design/icons'
import { Image, Menu, Spin } from 'antd'
import R from 'assets'
import styles from 'common/components/styles/WhiteBox.module.css'
import { colors } from 'common/theme'
import { logoutAction } from 'features/auth/AuthSlice'
import { HeaderStyle } from 'features/styles/Styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history from 'utils/history'
import ChangePass from './Dropdown/ChangePass'
import { PersonInfo } from './Dropdown/PersonInfo'

const { SubMenu } = Menu

export default function RHeader() {
  const dispatch = useDispatch()
  const authState = useSelector((state: any) => state.authReducer)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const ChangePassword = () => {
    setIsModalVisible(true)
  }
  const handlePersonInfo = () => {
    setShowInfo(true)
  }

  function handleClick(e: any) {
    if (e.key === 'logout') {
      dispatch(logoutAction())
    } else {
      history.push(e.key)
    }
  }

  function handleGetCurrentRouter() {
    return window.location.pathname
  }

  const userState = useSelector((state: any) => state.authReducer)?.userInfo

  useEffect(() => {
    if (userState) {
      localStorage.setItem('userTabs', JSON.stringify(userState.listTab))
    }
  }, [userState])

  return (
    <Spin spinning={authState.dialogLoading}>
      <HeaderStyle>
        <Image width={100} src={R.images.ic_logo} />
        <Menu
          key="MenuHeader"
          style={{
            backgroundColor: colors.orange,
            lineHeight: '28px',
            borderBottom: 0,
            color: 'white',
          }}
          triggerSubMenuAction="click"
          onClick={handleClick}
          mode="horizontal"
          selectedKeys={[handleGetCurrentRouter()]}
        >
          <SubMenu
            key="sub15"
            icon={<UserOutlined />}
            title={userState ? userState?.full_name : 'EZSale'}
          >
            {/* <Menu
              style={{
                padding: '20px 20px 20px 15px',
              }}
            >
              <a onClick={handlePersonInfo} className={styles.change_password}>
                <UserOutlined />
                &nbsp;&nbsp; Thông tin cá nhân
              </a>
            </Menu>
            <Menu
              style={{
                padding: '0px 0px 5px 14px',
              }}
            >
              <a onClick={ChangePassword} className={styles.change_password}>
                <RedoOutlined style={{ fontSize: 15 }} />
                &nbsp;&nbsp; Đổi mật khẩu
              </a>
            </Menu> */}
            <Menu.Item key={'logout'}>
              <LogoutOutlined />
              Đăng xuất
            </Menu.Item>
          </SubMenu>
        </Menu>
      </HeaderStyle>

      <PersonInfo
        visible={showInfo}
        isLoading={false}
        onCancel={() => {
          setShowInfo(false)
        }}
      />
      <ChangePass
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      />
    </Spin>
  )
}
