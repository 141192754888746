import { Route, Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Cookie from 'js-cookie'
import { Layout } from 'antd'
import React, { useEffect } from 'react'

import { getUserInfoAction } from 'features/auth/AuthSlice'
import RHeader from 'common/components/Header'
import LoginScreen from 'features/auth/Login'
import { SESSION_KEY } from 'common/config'
import RMenu from 'common/components/Menu'
import PrivateRoute from './PrivateRoute'
import MenuExpand from './MenuExpand'
import { colors } from 'common/theme'
import history from 'utils/history'
import Nav from './Nav'
import { WebSocket } from 'services/WebSocket'
import CheckToken from 'features/auth/CheckToken'

const { Sider } = Layout

const StyleContainerBox = styled.div``

const StyleSider = styled(Sider)`
  background-color: ${colors.white};
  min-height: calc(100vh - var(--header-height));
`

const StyleRHeader = styled(RHeader)``

export default function AppNavigator() {
  const session = Cookie.get(SESSION_KEY.SESSION)
  const userState = useSelector(
    (redux: Record<string, any>) => redux.authReducer?.userInfo
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (session) {
      dispatch(getUserInfoAction())
    }
  }, [session])

  useEffect(() => {
    if (session && userState) {
      WebSocket.init(session, userState?.id)
    }

    return () => {
      WebSocket.disconnect()
    }
  }, [session, userState])

  const MainNavigator = () => (
    <StyleContainerBox>
      <StyleRHeader />
      <Layout>
        <StyleSider breakpoint="sm">
          <RMenu />

          <MenuExpand />
        </StyleSider>

        <Nav />
      </Layout>
    </StyleContainerBox>
  )

  return (
    <Router history={history}>
      <Switch>
        <Route path={'/auth/:token'} exact component={CheckToken} />
        <Route path={'/login'} exact component={LoginScreen} />
        <PrivateRoute path="/" component={MainNavigator} />
      </Switch>
    </Router>
  )
}
