import { Layout } from 'antd'
import PrivateRoute from './PrivateRoute'
import screenRouter from './routerType'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

export default function Nav() {
  const history = useHistory()
  const currentUser = useSelector((state: any) => state.authReducer.userInfo)
  const userTabs = currentUser?.listTab || []

  useEffect(() => {
    if (userTabs.length > 0) {
      history.push('/' + userTabs[0].tab_name)
    }
  }, [userTabs.length])

  return (
    <>
      <Layout style={{ paddingTop: 10 }}>
        {screenRouter.map((val, index) => (
          <PrivateRoute
            key={index}
            path={`${val.path}`}
            component={val.component}
            exact
          />
        ))}
      </Layout>
    </>
  )
}
