import { FC, useState } from 'react'
import { Modal, Radio, Form, Input, message } from 'antd'
import { useHistory } from 'react-router'

import { addAllListDKKDToGroup } from '../business-registration/BusinessRegistrationAPI'
import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import DebounceSearchSelect from 'common/components/DebounceSearchSelect'
import { getListGroupCustomerVer2 } from '../facebook/FacebookApi'
import { addAllListGoogleToGroup } from '../google/GoogleApi'
import { ROUTER_PATH } from 'common/config'
import R from 'assets'

interface AddAllProps {
  payload: Record<string, any>
  total: number
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  from: string
}

const AddAllCustomerToGroupModal: FC<AddAllProps> = props => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [groupMode, setGroupMode] = useState<string>('Exists')
  const [isTransfer, setIsTransfer] = useState<boolean>(false)

  const handleCancel = () => {
    props.setVisible(false)
  }

  const handleChangeMode = (e: any) => {
    setGroupMode(e.target.value)
  }

  const handleTransferData = async (data: Record<string, any>) => {
    let payload = { ...props.payload, ...data }

    console.log('=================', { payload })

    if (groupMode === 'New') {
      if (payload.group_data_description === undefined)
        payload.group_data_description = ''
    }

    try {
      setIsTransfer(true)
      let addAllResponse
      switch (props.from) {
        case 'DKKD':
          addAllResponse = await addAllListDKKDToGroup(payload)
          break
        default:
          addAllResponse = await addAllListGoogleToGroup(payload)
      }

      history.push(
        `${ROUTER_PATH.SET_OF_CUSTOMER_DETAIL}/${addAllResponse.data}`
      )
      const durationTime = 3 * 60 * 1000
      message.success(
        'Với số lượng bản ghi lớn,danh sách khách hàng đã chuyển sẽ được tự động hiển thị trong khoảng 3 phút!'
      )
      setTimeout(() => {
        window.location.reload()
      }, durationTime)
    } catch (error) {
      console.error('Exception ' + error)
    } finally {
      setIsTransfer(false)
    }
  }

  return (
    <Modal
      title={R.strings().title_modal_add_customer_to_group}
      visible={props.visible}
      onCancel={handleCancel}
      footer={null}
    >
      <>
        <div style={{ fontSize: '17px', fontWeight: 700, color: 'green' }}>
          Đã chọn {props.total} khách hàng
        </div>

        <div style={{ margin: '10px 0px' }}>
          <div>{R.strings().label_customer_group}</div>

          <div>
            <Radio.Group onChange={handleChangeMode} value={groupMode}>
              <Radio value="Exists">{R.strings().options_group_exists}</Radio>
              <Radio value="New">
                {R.strings().options_create_new_customer_group}
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={handleTransferData}
        >
          {groupMode === 'Exists' ? (
            <Form.Item
              label={R.strings().label_customer_group}
              name="group_data_id"
              rules={[
                {
                  required: true,
                  message: R.strings()
                    .rules_error_message_choose_a_customer_group,
                },
              ]}
            >
              <DebounceSearchSelect
                mode="single"
                fetchOptions={getListGroupCustomerVer2}
                placeholder={R.strings().placeholder_select_customer_group}
                setChangeFilter={(
                  value: Record<string, any> | string | string[] | any
                ) => {
                  form.setFieldsValue({
                    group_data_id: value,
                  })
                }}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={R.strings().label_customer_group_name}
                name="group_data_name"
                rules={[
                  {
                    required: true,
                    message: R.strings()
                      .rules_error_message_please_enter_the_group_name,
                  },
                ]}
              >
                <Input
                  placeholder={
                    R.strings().placeholder_enter_customer_group_name
                  }
                />
              </Form.Item>

              <Form.Item
                label={R.strings().label_descriptions}
                name="group_data_description"
              >
                <Input placeholder={R.strings().placeholder_descriptions} />
              </Form.Item>
            </>
          )}

          <ButtonBottomModal
            text="Thêm"
            isLoadingButton={isTransfer}
            onCancel={() => props.setVisible(false)}
          ></ButtonBottomModal>
        </Form>
      </>
    </Modal>
  )
}
export default AddAllCustomerToGroupModal
