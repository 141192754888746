/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumb, Button, PageHeader, Tabs } from 'antd'
import R from 'assets'
import { Wrapper } from 'common/components/Screens'
import { BreadcrumbStyle } from 'features/styles/Styles'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ConvertFacebookModal from './components/ConvertFacebookModal'
import CustomerData from './components/CustomerData'
import CustomerResources from './components/CustomerResources'

const { TabPane } = Tabs

interface paramProps {
  created_at?: string
  id?: number
  is_active?: number
  key?: number
  name?: string
  sub_groups?: any
  total_member_group?: number
  total_subgroup?: number
  total_sublinkgroup?: number
}
export default function FacebookDetail(props: any) {
  const location = useLocation()

  const params: any = location.state
  const [name, setName] = useState<string>('')
  const [
    showConvertFacebookModal,
    setShowConvertFacebookModal,
  ] = useState<boolean>(false)
  const [convertedFBList, setConvertedFBList] = useState<Record<string, any>>({
    success: [],
    total: [],
  })

  const [
    showResultConvertFacebookModal,
    setShowResultConvertFacebookModal,
  ] = useState<boolean>(false)

  const [activeKey, setActiveKey] = useState('1')
  const [showAddSourceModal, setShowAddSourceModal] = useState<boolean>(false)

  return (
    <>
      <Wrapper>
        <div style={{ marginBottom: 5 }}>
          <BreadcrumbStyle>
            <Breadcrumb.Item>
              <Link to={'/'}>Thông tin</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={location}>{params?.name}</Link>
            </Breadcrumb.Item>
          </BreadcrumbStyle>
          <PageHeader
            style={{ minHeight: '5%', maxHeight: '10%', marginTop: -10 }}
            className="site-page-header"
            title={params?.name}
          />
        </div>
        <Tabs
          style={{ padding: 0 }}
          activeKey={activeKey}
          type="card"
          onTabClick={e => setActiveKey(e)}
        >
          <TabPane tab={R.strings().title_header_customer_data} key="1">
            <CustomerData
              setShowAddSourceModal={setShowAddSourceModal}
              setActiveKey={setActiveKey}
            />
          </TabPane>
          <TabPane tab={R.strings().title_header_customer_resources} key="2">
            <CustomerResources
              setName={setName}
              showAddSourceModal={showAddSourceModal}
              setShowAddSourceModal={setShowAddSourceModal}
            />
          </TabPane>
        </Tabs>
      </Wrapper>

      <ConvertFacebookModal
        show={showConvertFacebookModal}
        setConvertedFBList={setConvertedFBList}
        setShowConvertFacebookModal={setShowConvertFacebookModal}
        setShowResultConvertFacebookModal={setShowResultConvertFacebookModal}
      />
    </>
  )
}
