import React from 'react'
import images from 'assets/imagesAsset'

interface SearchToShowDataProps {
  title?: string
}

function SearchToShowData({ title }: SearchToShowDataProps) {
  return (
    <>
      <img
        src={images.no_data}
        alt="no-data"
        style={{ width: '170px', height: '170px' }}
      />
      <p style={{ fontSize: '16px' }}>
        {title || 'Thực hiện tìm kiếm để hiển thị dữ liệu'}
      </p>
    </>
  )
}

export default SearchToShowData
