import React, { useEffect, useRef, useState } from 'react'
import { Popconfirm, Switch } from 'antd'

import { DATA_CRAWL_STATUS, IS_ACTIVE } from 'utils/constants'
import R from 'assets'

interface ISwitchStatus {
  id: string
  loading: boolean
}

interface IPopConfirmCustom {
  confirmationWaitingTime: number //ms - mili giây
  data: Record<string, any>
  keyCheckActiveSwitch: string[]
  callback: () => Promise<any>
}

const STEP_TIME = 1000 //ms

const handleLogicGateAND = (data: Record<string, any>, keyCheck: string[]) => {
  let resultLogicGateAND = true
  for (const iterator of keyCheck) {
    if (iterator === 'status_spy' || iterator === 'status_update_content') {
      if (data[iterator] === DATA_CRAWL_STATUS.SUCCESS) {
        resultLogicGateAND = false
      }
    } else {
      if (data[iterator] !== IS_ACTIVE.ACTIVE) {
        resultLogicGateAND = false
      }
    }
  }

  return resultLogicGateAND
}

function PopConfirmSwitchCustom(props: IPopConfirmCustom) {
  const {
    data,
    callback,
    keyCheckActiveSwitch,
    confirmationWaitingTime,
  } = props

  const timer = useRef<any>()

  const [checked, setChecked] = useState<boolean>(() =>
    handleLogicGateAND(data, keyCheckActiveSwitch)
  )

  useEffect(() => {
    setChecked(() => handleLogicGateAND(data, keyCheckActiveSwitch))
  }, [data])

  const [switchStatus, setSwitchStatus] = useState<ISwitchStatus>({
    id: '',
    loading: false,
  })

  const [countDown, setCountDown] = useState<number>(confirmationWaitingTime)
  const [disableOkButton, setDisableOkButton] = useState<boolean>(true)

  useEffect(() => {
    if (switchStatus.id === data?.id) {
      timer.current = setInterval(() => {
        if (countDown !== 0) {
          setCountDown(prev => prev - STEP_TIME)
        }
      }, STEP_TIME)
    }
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [switchStatus])

  useEffect(() => {
    if (countDown === 0 && timer.current) {
      clearInterval(timer.current)
      setDisableOkButton(false)
    }
  }, [countDown])

  return (
    <Popconfirm
      placement="bottomRight"
      title={
        checked
          ? R.strings().action_turn_off_activity
          : R.strings().action_turn_on_activity
      }
      okText={`${countDown ? `(${countDown / STEP_TIME})` : ''} ${
        checked ? R.strings().action_turn_off : R.strings().action_turn_on
      }`}
      okButtonProps={{
        disabled: disableOkButton,
        type: 'primary',
        danger: checked,
      }}
      onConfirm={async (event: any) => {
        event.stopPropagation()
        try {
          setSwitchStatus((prev: ISwitchStatus) => ({ ...prev, loading: true }))
          await callback()
        } catch (error) {
          console.log('error', { error })
        } finally {
          setSwitchStatus({
            id: '',
            loading: false,
          })
        }
      }}
      cancelText={R.strings().action_back}
      onCancel={(event: any) => {
        event.stopPropagation()
        event.preventDefault()
        clearInterval(timer.current)
        setCountDown(confirmationWaitingTime)
        setDisableOkButton(true)
      }}
      onVisibleChange={(visible: boolean) => {
        if (!visible) {
          clearInterval(timer.current)
          setCountDown(confirmationWaitingTime)
          setDisableOkButton(true)
        }
      }}
    >
      <Switch
        checked={checked}
        loading={data.id === switchStatus.id && switchStatus.loading}
        onChange={async (checked: boolean, event: Event) => {
          event.stopPropagation()
          setSwitchStatus({
            id: data.id,
            loading: false,
          })
        }}
      />
    </Popconfirm>
  )
}

export default PopConfirmSwitchCustom
