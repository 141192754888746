import { Fragment, useEffect, useState } from 'react'
import { message, Modal, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import styled from 'styled-components'
import moment from 'moment'

import { changeLinkPost, deleteLinkPost, getListPost } from '../FacebookApi'
import RenderItemDataCrawStatus from 'common/components/ItemDataCrawStatus'
import PopConfirmSwitchCustom from 'common/components/PopConfirm'
import { ButtonDelete } from 'common/components/Buttons'
import CustomerCommentList from './CustomerCommentList'
import { Wrapper } from 'common/components/Screens'
import Ellipsis from 'common/components/Ellipsis'
import {
  DATA_CRAWL_STATUS,
  IS_ACTIVE,
  TYPE_SOURCE_FACEBOOK,
} from 'utils/constants'
import R from 'assets'

import { DataCrawStatusStyle, TableAntdStyle } from 'features/styles/Styles'
import styles from '../styles/PostList.module.css'

type Props = {
  sub_group_id: number
}

type Param = {
  type?: number
  sub_group_id: number
  page?: number
}

const ColumnStatusStyle = styled.div`
  width: 100%;
  height: 100%;

  text-align: center;

  cursor: pointer;
`
const CONFIRMATION_WAITING_TIME = 2000

export default function PostList({ sub_group_id }: Props) {
  const [param, setParam] = useState<Param>({
    // type: 2,
    sub_group_id: sub_group_id,
  })
  const [dataTable, setDataTable] = useState([])
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
  const [isLoadingSwitch, setIsLoadingSwitch] = useState<any>({
    id: '',
    loading: false,
  })
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })
  const [
    isDetailListCustomerModalVisible,
    setIsDetailListCustomerModalVisible,
  ] = useState<boolean>(false)

  const [
    isOnlyChooseCustomerWithPhone,
    setIsOnlyChooseCustomerWithPhone,
  ] = useState<boolean>(false)

  const [isFilterMemberCustomer, setIsFilterMemberCustomer] = useState<boolean>(
    false
  )
  const [pickedPostDetail, setPickedPostDetail] = useState<any>({
    link_fb_id: '',
    id: 0,
    key: 0,
  })

  const getPost = async () => {
    setIsLoadingTable(true)
    try {
      const response = await getListPost(param)
      // console.log(response)
      const dataTable = response.data.map((item: any) => ({
        ...item,
        key: item.id,
      }))
      setDataTable(dataTable)
      setPaging({
        total: response.paging.totalItemCount,
        current: response.paging.page,
        pageSize: response.paging.limit,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTable(false)
    }
  }

  useEffect(() => {
    getPost()
  }, [param])

  const columns: ColumnsType<Record<string, any>> = [
    {
      width: '5%',
      title: 'STT',
      align: 'center',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
    },
    {
      title: 'Nội dung bài viết',
      dataIndex: 'message',
      key: 'message',
      width: '45%',
      render: (value: string) => (
        <td>{value && <Ellipsis content={value} />}</td>
      ),
    },
    {
      title: 'Trang/nhóm',
      dataIndex: 'page_name',
      key: 'page_name',
    },
    {
      title: 'Link bài viết',
      dataIndex: 'link_fb_id',
      key: 'link_fb_id',
      render: (value: string) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://facebook.com/${value}`}
          onClick={e => e.stopPropagation()}
        >
          {`https://facebook.com/${value}`}
        </a>
      ),
    },
    {
      align: 'center',
      title: 'Thời gian đăng',
      dataIndex: 'created_ads',
      key: 'created_ads',
      width: 150,
      render: (value: string) => moment(value).format('DD/MM/YYYY'),
    },
    // {
    //   title: 'Lượt comment',
    //   dataIndex: '',
    //   key: '',
    // render: (value: string) => <td>{value ? value : '--'}</td>,
    // },
    {
      title: '',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      width: 150,
      render: (status: number, record: Record<string, any>, index: number) => {
        const {
          id,
          type,
          status_spy,
          status_update_content,
          type_group,
        } = record

        return (
          <ColumnStatusStyle onClick={e => e.stopPropagation()}>
            <DataCrawStatusStyle>
              {record.type === 'post' &&
                (type_group === TYPE_SOURCE_FACEBOOK.GROUP ? (
                  <Fragment>
                    <RenderItemDataCrawStatus
                      statusData={[status_spy, status_update_content]}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <RenderItemDataCrawStatus
                      statusData={[status_update_content]}
                    />
                  </Fragment>
                ))}
            </DataCrawStatusStyle>

            <Space size="middle">
              {record.type === 'post' &&
                (type_group === TYPE_SOURCE_FACEBOOK.GROUP ? (
                  <PopConfirmSwitchCustom
                    confirmationWaitingTime={CONFIRMATION_WAITING_TIME}
                    data={record}
                    keyCheckActiveSwitch={[
                      'status_spy',
                      'status_update_content',
                    ]}
                    callback={async () => {
                      const { status } = await changeLinkPost({
                        link_id: id,
                        is_active:
                          status_spy === DATA_CRAWL_STATUS.SUCCESS &&
                          status_update_content === DATA_CRAWL_STATUS.SUCCESS
                            ? IS_ACTIVE.ACTIVE
                            : IS_ACTIVE.DEACTIVE,
                      })
                      message.success(
                        R.strings().message_status_change_successful
                      )
                      if (status) {
                        getPost()
                      }
                    }}
                  />
                ) : (
                  <PopConfirmSwitchCustom
                    confirmationWaitingTime={CONFIRMATION_WAITING_TIME}
                    data={record}
                    keyCheckActiveSwitch={['status_update_content']}
                    callback={async () => {
                      const { status } = await changeLinkPost({
                        link_id: id,
                        is_active:
                          status_update_content === DATA_CRAWL_STATUS.SUCCESS
                            ? IS_ACTIVE.ACTIVE
                            : IS_ACTIVE.DEACTIVE,
                      })
                      message.success(
                        R.strings().message_status_change_successful
                      )
                      if (status) {
                        getPost()
                      }
                    }}
                  />
                ))}

              <ButtonDelete
                //confirm
                title={R.strings().confirm_remove_campaign}
                cancelText={R.strings().action_back}
                okText={R.strings().action_remove}
                onConfirm={async () => {
                  try {
                    await deleteLinkPost({
                      link_id: id,
                      type: type,
                    })
                    message.success('Xoá thành công')
                  } catch (error) {
                    console.log(error)
                  } finally {
                    getPost()
                  }
                }}
                //button
                icon={<DeleteOutlined />}
              />
            </Space>
          </ColumnStatusStyle>
        )
      },
    },
  ]

  return (
    <Wrapper>
      <div className={styles.headerExpandText}>
        {R.strings().title_header_post_list}
      </div>

      <div>
        <TableAntdStyle
          size="small"
          scroll={{
            scrollToFirstRowOnChange: true,
          }}
          bordered
          dataSource={dataTable}
          loading={isLoadingTable}
          columns={columns}
          onRow={(record?: any) => {
            return {
              onClick: () => {
                const { link_fb_id, id, key } = record
                setPickedPostDetail({ link_fb_id, id, key })
                setIsDetailListCustomerModalVisible(true)
              },
            }
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParam({ ...param, page })
            },
          }}
        />
      </div>

      <Modal
        visible={isDetailListCustomerModalVisible}
        onCancel={() => setIsDetailListCustomerModalVisible(false)}
        width={1366}
        footer={null}
        destroyOnClose
      >
        <CustomerCommentList
          isFilterMemberCustomer={isFilterMemberCustomer}
          isOnlyChooseCustomerWithPhone={isOnlyChooseCustomerWithPhone}
          page_id={pickedPostDetail.link_fb_id}
          comment_id={pickedPostDetail.id}
          uniqueKey={pickedPostDetail.key}
        />
      </Modal>
    </Wrapper>
  )
}
