import React, { useEffect, useState } from 'react'
import { Col, message, Row, Select, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { ColumnsType } from 'antd/lib/table'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import moment from 'moment'

import ButtonSetting from 'common/components/Buttons/ButtonSetting'
import PopConfirmSwitchCustom from 'common/components/PopConfirm'
import { IS_ACTIVE, TYPE_SOURCE_FACEBOOK } from 'utils/constants'
import { ButtonDelete } from 'common/components/Buttons'
import { handleKeywordSource } from '../FacebookSlice'
import SetSource from './SetSource'
import PostList from './PostList'
import {
  changeActiveSource,
  deleteSource,
  getDetailCampaign,
} from '../FacebookApi'
import R from 'assets'

const { Option } = Select

type IParamDetailCampaign = {
  group_id: number
  type_group: string
  page?: number
}

interface ICustomerResourceProp {
  setName: React.Dispatch<React.SetStateAction<string>>
  showAddSourceModal: any
  setShowAddSourceModal: any
}

const CONFIRMATION_WAITING_TIME = 5000

export default function CustomerResources(props: ICustomerResourceProp) {
  const paramLocation: any = useParams()

  const dispatch = useDispatch()
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
  const [sourceSelected, setSourceSelected] = useState<string>('')
  const [rowCurrentSelected, setRowCurrentSelected] = useState<Array<string>>(
    []
  )

  // const [showAddSourceModal, setShowAddSourceModal] = useState<boolean>(false)
  const [
    showKeywordManagementModal,
    setShowKeywordManagementModal,
  ] = useState<boolean>(false)
  const [showSelectGroupModal, setShowSelectGroupModal] = useState<boolean>(
    false
  )
  const [
    showAddEditKeywordModal,
    setShowAddEditKeywordModal,
  ] = useState<boolean>(false)
  const [
    showEditKeywordSourceModal,
    setShowEditKeywordSourceModal,
  ] = useState<boolean>(false)
  const [dataCampaign, setDataCampaign] = useState<any>([])

  const [
    isOnlyChooseCustomerWithPhone,
    setIsOnlyChooseCustomerWithPhone,
  ] = useState<boolean>(false)

  const [
    paramDetailCampaign,
    setParamDetailCampaign,
  ] = useState<IParamDetailCampaign>({
    group_id: paramLocation.id,
    type_group: sourceSelected,
  })

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const columns: ColumnsType<Record<string, any>> = [
    {
      width: '10%',
      align: 'center',
      title: 'Loại nguồn',
      dataIndex: 'df_type_group_id',
      key: 'df_type_group_id',
      render: (value: number) => {
        switch (value) {
          case TYPE_SOURCE_FACEBOOK.GROUP:
            return R.strings().options_group
          case TYPE_SOURCE_FACEBOOK.PAGE:
            return R.strings().options_page
          case TYPE_SOURCE_FACEBOOK.POST:
            return R.strings().options_post
          case TYPE_SOURCE_FACEBOOK.ADVERTISEMENT:
            return R.strings().options_advertisement
          default:
            return ''
        }
      },
    },
    {
      width: '15%',
      align: 'center',
      title: 'Số lượng nhóm',
      dataIndex: 'count_group',
      key: 'count_group',
    },
    {
      width: '15%',
      align: 'center',
      title: 'Số lượng bài viết',
      dataIndex: 'total_sublinkgroup',
      key: 'total_sublinkgroup',
      render: (record: Record<string, any>) => (record ? record : '--'),
    },
    {
      width: '15%',
      align: 'center',
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      // width: 150,
      render: (text: any, record: any, index: number) => {
        return (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <Row gutter={[16, 16]} align="middle">
              <Col span={8}>
                {record.df_type_group_id === TYPE_SOURCE_FACEBOOK.GROUP ? (
                  <ButtonSetting
                    onClickButton={(event: any) => {
                      event.stopPropagation()
                      dispatch(
                        handleKeywordSource({
                          id: record.id,
                          keyword: record.sub_group_keywords,
                        })
                      )
                      setShowEditKeywordSourceModal(true)
                    }}
                  />
                ) : record.df_type_group_id === TYPE_SOURCE_FACEBOOK.PAGE ? (
                  <ButtonSetting
                    onClickButton={(event: any) => {
                      event.stopPropagation()
                      dispatch(
                        handleKeywordSource({
                          id: record.id,
                          keyword: record.sub_group_keywords,
                        })
                      )
                      setShowEditKeywordSourceModal(true)
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </Col>

              <Col span={8}>
                <PopConfirmSwitchCustom
                  confirmationWaitingTime={CONFIRMATION_WAITING_TIME}
                  data={record}
                  keyCheckActiveSwitch={['check_facebook_post_run']}
                  callback={async () => {
                    const { sub_group_id, check_facebook_post_run } = record

                    const { status } = await changeActiveSource({
                      sub_group_id: sub_group_id,
                      is_active:
                        check_facebook_post_run === IS_ACTIVE.ACTIVE
                          ? IS_ACTIVE.DEACTIVE
                          : IS_ACTIVE.ACTIVE,
                    })
                    message.success(
                      R.strings().message_status_change_successful
                    )

                    if (status) {
                      getDetail()
                    }
                  }}
                />
              </Col>

              <Col span={8}>
                <ButtonDelete
                  //confirm
                  title={R.strings().confirm_remove_campaign}
                  cancelText={R.strings().action_back}
                  okText={R.strings().action_remove}
                  onConfirm={async () => {
                    try {
                      await deleteSource({
                        sub_group_id: record.sub_group_id,
                      })
                      message.success('Xoá thành công')
                    } catch (error) {
                      console.log(error)
                    } finally {
                      getDetail()
                    }
                  }}
                  //button
                  icon={<DeleteOutlined />}
                />
              </Col>
            </Row>
          </div>
        )
      },
    },
  ]

  const getDetail = async () => {
    setIsLoadingTable(true)
    try {
      const payload: {
        group_id: number
        type_group: string
        phone: 1 | 0
      } = {
        ...paramDetailCampaign,
        phone: isOnlyChooseCustomerWithPhone ? 1 : 0,
      }
      const response = await getDetailCampaign(payload)
      const dataTable = response.data?.result.map(
        (item: any, index: number) => {
          return { ...item, key: item.sub_group_id }
        }
      )
      // console.log(response.data.group_name)

      props.setName(response.data.group_name)
      setDataCampaign(dataTable)
      setPaging({
        total: response.data?.paging.totalItemCount,
        current: response.data?.paging.page,
        pageSize: response.data?.paging.limit,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingTable(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [paramDetailCampaign, isOnlyChooseCustomerWithPhone])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <div style={{ minWidth: '400px' }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Chọn nguồn"
            optionFilterProp="children"
            allowClear
            // defaultValue={sourceSelected}
            onChange={(value: string) => {
              setSourceSelected(value)
              setParamDetailCampaign({
                ...paramDetailCampaign,
                type_group: value,
              })
            }}
          >
            <Option value={TYPE_SOURCE_FACEBOOK.GROUP}>
              {R.strings().options_group}
            </Option>
            <Option value={TYPE_SOURCE_FACEBOOK.PAGE}>
              {R.strings().options_page}
            </Option>
            <Option value={TYPE_SOURCE_FACEBOOK.POST}>
              {R.strings().options_post}
            </Option>
          </Select>
        </div>

        <div>
          <ButtonSetting
            text={R.strings().action_source_setting}
            onClickButton={() => props.setShowAddSourceModal(true)}
          />
        </div>
      </div>

      <div>
        <Row justify="end" style={{ marginBottom: '16px' }}>
          <Checkbox
            checked={isOnlyChooseCustomerWithPhone}
            onChange={() =>
              setIsOnlyChooseCustomerWithPhone(!isOnlyChooseCustomerWithPhone)
            }
          >
            Lọc người cmt có ID
          </Checkbox>
        </Row>
        <Table
          className="table-expanded-custom-no-image"
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
          }}
          bordered
          dataSource={dataCampaign}
          loading={isLoadingTable}
          columns={columns}
          expandedRowKeys={[...rowCurrentSelected]}
          onRow={(record: any) => ({
            onClick: (event: any) => {
              if (rowCurrentSelected.includes(record.sub_group_id)) {
                setRowCurrentSelected(
                  rowCurrentSelected.filter(
                    item => record.sub_group_id !== item
                  )
                )
              } else {
                setRowCurrentSelected([
                  ...rowCurrentSelected,
                  record.sub_group_id,
                ])
              }
            },
          })}
          expandable={{
            expandedRowRender: (record: any) => (
              <PostList sub_group_id={record.sub_group_id} />
            ),
            onExpand: (expanded, record) => {
              if (rowCurrentSelected.includes(record.sub_group_id)) {
                setRowCurrentSelected(
                  rowCurrentSelected.filter(
                    item => record.sub_group_id !== item
                  )
                )
              } else {
                setRowCurrentSelected([
                  ...rowCurrentSelected,
                  record.sub_group_id,
                ])
              }
            },
          }}
          pagination={{
            ...paging,
            onChange: async (page, pageSize) => {
              setParamDetailCampaign({ ...paramDetailCampaign, page })
            },
          }}
        />
      </div>

      {(props.showAddSourceModal ||
        showKeywordManagementModal ||
        showSelectGroupModal ||
        showAddEditKeywordModal ||
        showEditKeywordSourceModal) && (
        <SetSource
          group_id={parseInt(paramLocation?.id)}
          showAddSourceModal={props.showAddSourceModal}
          setShowAddSourceModal={props.setShowAddSourceModal}
          showKeywordManagementModal={showKeywordManagementModal}
          setShowKeywordManagementModal={setShowKeywordManagementModal}
          showSelectGroupModal={showSelectGroupModal}
          setShowSelectGroupModal={setShowSelectGroupModal}
          showAddEditKeywordModal={showAddEditKeywordModal}
          setShowAddEditKeywordModal={setShowAddEditKeywordModal}
          showEditKeywordSourceModal={showEditKeywordSourceModal}
          setShowEditKeywordSourceModal={setShowEditKeywordSourceModal}
          getDataCustomerResource={getDetail}
        />
      )}
    </>
  )
}
