import { HomeOutlined, ShopOutlined } from '@ant-design/icons'
import { Menu, Spin } from 'antd'
import R from 'assets'
import { ROUTER_PATH } from 'common/config'
import { logoutAction } from 'features/auth/AuthSlice'
import { Key, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history from 'utils/history'

const { SubMenu } = Menu

export default function RMenu() {
  const dispatch = useDispatch()
  const authState = useSelector((state: any) => state.authReducer)
  const [openKeys, setOpenKeys] = useState<Array<any>>(['customer_resources'])
  const listTabs = authState?.userInfo?.listTab?.map(
    (item: any) => item.tab_name
  )

  function handleClick(e: any) {
    if (e.key === 'logout') {
      dispatch(logoutAction())
    } else {
      history.push(e.key)
    }
  }

  function handleGetCurrentRouter() {
    return window.location.pathname
  }

  const rootSubMenuKeys = Object.values(ROUTER_PATH)

  const handleChangeMenu = (keys: Key[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubMenuKeys.indexOf(latestOpenKey as string) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const AdminMenu = () => {
    const userTabs: any = useSelector(
      (state: any) => state?.authReducer?.userInfo?.listTab || []
    )

    const renderSubMenuItem = () => {
      if (userTabs?.length > 0) {
        return userTabs.map((tab: any) => {
          switch (tab.tab_name) {
            case 'facebook':
              return listTabs?.includes('facebook') ? (
                <>
                  <Menu.Item key={ROUTER_PATH.FACEBOOK} icon={<HomeOutlined />}>
                    {R.strings().title_header_facebook}
                  </Menu.Item>
                </>
              ) : null

            case 'google':
              return listTabs?.includes('google') ? (
                <>
                  <Menu.Item key={ROUTER_PATH.GOOGLE} icon={<HomeOutlined />}>
                    {R.strings().title_header_google}
                  </Menu.Item>
                </>
              ) : null
            case 'dang-ky-kinh-doanh':
              return listTabs?.includes('dang-ky-kinh-doanh') ? (
                <>
                  <Menu.Item
                    key={ROUTER_PATH.BUSINESS_REGISTRATION}
                    icon={<HomeOutlined />}
                  >
                    {R.strings().title_header_business_registration}
                  </Menu.Item>
                </>
              ) : null
            default:
              return null
          }
        })
      }
      return null
    }

    const renderMenuItem = () => {
      if (userTabs.length > 0) {
        return userTabs.map((tab: any) => {
          switch (tab.tab_name) {
            case 'tap-khach-hang':
              return (
                <Menu.Item
                  key={ROUTER_PATH.SET_OF_CUSTOMER}
                  icon={<HomeOutlined />}
                  children={R.strings().title_header_set_of_customers}
                />
              )
            default:
              return null
          }
        })
      }
      return null
    }

    return (
      <Menu
        onClick={handleClick}
        openKeys={openKeys}
        onOpenChange={e => handleChangeMenu(e)}
        mode="inline"
        selectedKeys={[handleGetCurrentRouter()]}
      >
        <SubMenu
          key="customer_resources"
          icon={<ShopOutlined />}
          title={R.strings().title_header_customer_resources}
        >
          {renderSubMenuItem()}
        </SubMenu>
        {renderMenuItem()}
      </Menu>
    )
  }

  return (
    <Spin spinning={authState.dialogLoading}>
      <AdminMenu />
    </Spin>
  )
}
