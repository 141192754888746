import React, { useEffect, useState } from 'react'
import { Breadcrumb, message, PageHeader, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { GROUP_TYPE, IS_ACTIVE } from 'utils/constants'
import AddCustomerToGroupModal from '../components/AddCustomerToGroupModal'
import { ButtonDelete, ButtonFixed } from 'common/components/Buttons'
import ConvertFacebookModal from './components/ConvertFacebookModal'
import PopConfirmSwitchCustom from 'common/components/PopConfirm'
import { Wrapper } from 'common/components/Screens'
import { ROUTER_PATH } from 'common/config'
import Filter from './components/Filter'
import R from 'assets'

import { BreadcrumbStyle, TableAntdStyle } from 'features/styles/Styles'
import { deleteCampaign, getListCampaign, updateCampaign } from './FacebookApi'
import moment from 'moment'
import ResultConvertFacebookModal from './components/ResultConvertFacebookModal'
import { useSelector } from 'react-redux'
import { userInfo } from 'os'

const styleButtonPageHeader = {
  backgroundColor: 'orange',
  border: 'none',
  color: 'white',
  borderRadius: '5px',
  fontWeight: '700',
}

type IParam = {
  search: string
  page?: number
  limit: number
}

const CONFIRMATION_WAITING_TIME = 5000

export default function Facebook() {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [convertedFBList, setConvertedFBList] = useState<Record<string, any>>({
    success: [],
    total: [],
  })
  const [choosenConvertedFBList, setChoosenConvertedFBList] = useState<any>([])
  const [
    showConvertFacebookModal,
    setShowConvertFacebookModal,
  ] = useState<boolean>(false)
  const [
    showResultConvertFacebookModal,
    setShowResultConvertFacebookModal,
  ] = useState<boolean>(false)
  const [
    showAddCustomerToGroupModal,
    setShowAddCustomerToGroupModal,
  ] = useState<boolean>(false)

  const [dataCampaign, setDataCampaign] = useState<any>([])

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  })

  const [param, setParam] = useState<IParam>({
    search: '',
    page: paging.current,
    limit: 12,
  })

  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

  const getDataCampaign = async () => {
    try {
      setIsLoading(true)
      const response = await getListCampaign(param)
      const data = response.data.results?.map((item: any) => ({
        ...item,
        key: item.id,
      }))
      setDataCampaign(data)
      setPaging({
        total: response.data?.paging.count,
        current: Number(response.data?.paging.page),
        pageSize: response.data.paging.limit,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsSearchLoading(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDataCampaign()
  }, [param.search, param.page])

  const columns: ColumnsType<Record<string, any>> = [
    {
      width: '8%',
      title: 'STT',
      align: 'center',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: any) => (
        <td style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </td>
      ),
      ellipsis: true,
    },
    {
      width: '25%',
      title: 'Tên chiến dịch',
      dataIndex: 'name',
      key: 'name',
    },
    {
      width: '15%',
      align: 'center',
      title: 'Số nguồn',
      dataIndex: 'total_subgroup',
      key: 'total_subgroup',
    },
    {
      align: 'center',
      width: '15%',
      title: 'Số bài viết',
      dataIndex: 'total_sublinkgroup',
      key: 'total_sublinkgroup',
    },
    {
      width: '25%',
      align: 'center',
      title: 'Tổng số KH',
      dataIndex: 'total_member_group',
      key: 'total_member_group',
    },
    {
      align: 'center',
      width: '10%',
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => moment(value).format('DD/MM/YYYY'),
    },
    {
      width: '10%',
      title: '',
      dataIndex: '',
      key: '',
      render: (record: any) => {
        return (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <Space size="middle">
              <PopConfirmSwitchCustom
                confirmationWaitingTime={CONFIRMATION_WAITING_TIME}
                data={record}
                keyCheckActiveSwitch={['is_active']}
                callback={async () => {
                  const { status } = await updateCampaign({
                    group_id: record.id,
                    name: record.name,
                    type: GROUP_TYPE.FACEBOOK,
                    is_active:
                      record.is_active === IS_ACTIVE.ACTIVE
                        ? IS_ACTIVE.DEACTIVE
                        : IS_ACTIVE.ACTIVE,
                  })
                  message.success(R.strings().message_status_change_successful)

                  if (status) {
                    getDataCampaign()
                  }
                }}
              />

              <ButtonDelete
                //confirm
                title={R.strings().confirm_remove_campaign}
                cancelText={R.strings().action_back}
                okText={R.strings().action_remove}
                onConfirm={async () => {
                  try {
                    const payload = { group_id: record.id }
                    await deleteCampaign(payload)
                    message.success(`Xoá thành công chiến dịch ${record?.name}`)
                    getDataCampaign()
                  } catch (error) {
                    console.log(error)
                  }
                }}
                //button
                icon={<DeleteOutlined />}
              />
            </Space>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Wrapper>
        <BreadcrumbStyle>
          <Breadcrumb.Item>
            <Link to={'/'}>Thông tin</Link>
          </Breadcrumb.Item>
        </BreadcrumbStyle>

        <PageHeader
          style={{ minHeight: '5%', maxHeight: '10%' }}
          className="site-page-header"
          title={R.strings().title_header_customer_resources_facebook}
          extra={[
            <ButtonFixed
              text={'Convert FB'}
              style={styleButtonPageHeader}
              onClickButton={() => setShowConvertFacebookModal(true)}
            />,
          ]}
        />

        <div style={{ marginBottom: '20px', marginTop: '10px' }}>
          <Filter
            isSearchLoading={isSearchLoading}
            onSearchSubmit={(searchKey: string) => {
              setIsSearchLoading(true)
              setParam({ ...param, search: searchKey, page: 1 })
            }}
          />
        </div>

        <div>
          <TableAntdStyle
            // className="table-expanded-custom-no-image"
            columns={columns}
            dataSource={dataCampaign}
            loading={isLoading}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: '800px',
            }}
            bordered
            onRow={(record: any, rowIndex) => ({
              onClick: () => {
                history.push({
                  pathname: `${ROUTER_PATH.FACEBOOK_DETAIL}/${record?.id}`,
                  state: record,
                })
              },
            })}
            pagination={{
              ...paging,
              showSizeChanger: false,
              onChange: async (page, pageSize) => {
                setParam({ ...param, page })
              },
            }}
          />
        </div>
      </Wrapper>

      {showConvertFacebookModal && (
        <ConvertFacebookModal
          show={showConvertFacebookModal}
          setConvertedFBList={setConvertedFBList}
          setShowConvertFacebookModal={setShowConvertFacebookModal}
          setShowResultConvertFacebookModal={setShowResultConvertFacebookModal}
        />
      )}

      {showResultConvertFacebookModal && (
        <ResultConvertFacebookModal
          show={showResultConvertFacebookModal}
          convertedFBList={convertedFBList}
          choosenConvertedFBList={choosenConvertedFBList}
          setChoosenConvertedFBList={setChoosenConvertedFBList}
          setShowResultConvertFacebookModal={setShowResultConvertFacebookModal}
          setShowConvertFacebookModal={setShowConvertFacebookModal}
          setShowAddCustomerToGroupModal={setShowAddCustomerToGroupModal}
        />
      )}

      {showAddCustomerToGroupModal && (
        <AddCustomerToGroupModal
          show={showAddCustomerToGroupModal}
          choosenConvertedFBList={choosenConvertedFBList}
          setShowAddCustomerToGroupModal={setShowAddCustomerToGroupModal}
        />
      )}
    </>
  )
}
