import React, { useState } from 'react'
import { Form, Input, message, Modal, Radio } from 'antd'

import ButtonBottomModal from 'common/components/Buttons/ButtonBottomModal'
import DebounceSearchSelect from 'common/components/DebounceSearchSelect'
import { OPTION_SELECT_CUSTOMER_GROUP } from 'utils/constants'
import {
  getListGroupCustomerVer2,
  transferCustomerData,
} from '../facebook/FacebookApi'
import R from 'assets'

type Props = {
  show?: boolean
  page_id?: string
  choosenConvertedFBList?: any
  transferAll?: boolean
  filter?: number | string
  group_id?: string
  setTransferedData?: React.Dispatch<React.SetStateAction<boolean>>
  setShowAddCustomerToGroupModal: (show: boolean) => void
}

export default function AddCustomerToGroupModal({
  show,
  choosenConvertedFBList,
  setTransferedData,
  transferAll,
  filter,
  group_id,
  setShowAddCustomerToGroupModal,
}: Props) {
  const [form] = Form.useForm()
  const [selectedRadioButton, setSelectedRadioButton] = useState<number>(
    OPTION_SELECT_CUSTOMER_GROUP.GROUP_EXISTS
  )

  const [isTransfer, setIsTransfer] = useState<boolean>(false)

  const resultCheckPhone = choosenConvertedFBList.filter(
    (value: any) => !!value.phone
  )

  const handleCancel = () => {
    setShowAddCustomerToGroupModal(false)
  }

  const handleRadioButton = (event: any) => {
    setSelectedRadioButton(event.target.value)
  }

  const handleTransferData = async (data: any) => {
    let payload = { ...data, list_customer: resultCheckPhone }

    if (transferAll) {
      payload = {
        ...data,
        list_customer: [],
        type: filter,
        all: 1,
        group_id: Number(group_id),
      }
    } else if (selectedRadioButton === 2) {
      if (payload.group_data_description === undefined)
        payload.group_data_description = ''
    } else {
      payload = {
        ...payload,
      }
    }

    try {
      setIsTransfer(true)
      await transferCustomerData(payload)
      setShowAddCustomerToGroupModal(false)
      if (setTransferedData) {
        setTransferedData(true)
      }
      message.success(`Dữ liệu khách hàng đang được chuyển...`)
    } catch (error) {
      setIsTransfer(false)
      console.error('Exception ' + error)
    } finally {
      setIsTransfer(false)
    }
  }

  return (
    <Modal
      title={R.strings().title_modal_add_customer_to_group}
      visible={show}
      onCancel={handleCancel}
      footer={null}
    >
      <>
        {!transferAll && (
          <div style={{ fontSize: '17px', fontWeight: 700, color: 'green' }}>
            Đã chọn {resultCheckPhone && resultCheckPhone.length} khách hàng
          </div>
        )}
        <div style={{ margin: '10px 0px' }}>
          <div>{R.strings().label_customer_group}</div>

          <div>
            <Radio.Group
              onChange={handleRadioButton}
              value={selectedRadioButton}
            >
              <Radio value={OPTION_SELECT_CUSTOMER_GROUP.GROUP_EXISTS}>
                {R.strings().options_group_exists}
              </Radio>
              <Radio
                value={OPTION_SELECT_CUSTOMER_GROUP.CREATE_NEW_CUSTOMER_GROUP}
              >
                {R.strings().options_create_new_customer_group}
              </Radio>
            </Radio.Group>
          </div>
        </div>

        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={handleTransferData}
        >
          {selectedRadioButton === OPTION_SELECT_CUSTOMER_GROUP.GROUP_EXISTS ? (
            <Form.Item
              label={R.strings().label_customer_group}
              name="group_data_id"
              rules={[
                {
                  required: true,
                  message: R.strings()
                    .rules_error_message_choose_a_customer_group,
                },
              ]}
            >
              <DebounceSearchSelect
                mode="single"
                fetchOptions={getListGroupCustomerVer2}
                placeholder={R.strings().placeholder_select_customer_group}
                setChangeFilter={(
                  value: Record<string, any> | string | string[] | any
                ) => {
                  form.setFieldsValue({
                    group_data_id: value,
                  })
                }}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={R.strings().label_customer_group_name}
                name="group_data_name"
                rules={[
                  {
                    required: true,
                    message: R.strings()
                      .rules_error_message_please_enter_the_group_name,
                  },
                ]}
              >
                <Input
                  placeholder={
                    R.strings().placeholder_enter_customer_group_name
                  }
                />
              </Form.Item>

              <Form.Item
                label={R.strings().label_descriptions}
                name="group_data_description"
              >
                <Input placeholder={R.strings().placeholder_descriptions} />
              </Form.Item>
            </>
          )}

          <ButtonBottomModal
            text="Thêm"
            isLoadingButton={isTransfer}
            onCancel={() => setShowAddCustomerToGroupModal(false)}
          ></ButtonBottomModal>
        </Form>
      </>
    </Modal>
  )
}
