import { Spin } from 'antd'
import { SESSION_KEY } from 'common/config'
import Cookies from 'js-cookie'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ApiClient } from 'services/ApiService'

const CheckToken = () => {
  const params: any = useParams()

  React.useEffect(() => {
    if (params?.token) {
      const [token, user_id] = params?.token?.split('-')

      ApiClient.post(`user/oath/api`, {
        token,
        user_id,
      }).then((response: any) => {
        if (response?.status) {
          Cookies.set(SESSION_KEY.SESSION, response?.data?.token, {
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })
          window.location.href = '/'
        }
      })
    } else {
      window.location.href = '/'
    }
  }, [params?.token])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spin />
    </div>
  )
}

export default CheckToken
