import axios from 'axios'
import { ENVIRONMENT } from 'common/config'
import { ApiClient } from 'services/ApiService'

const dkkd = 'dkkd/'

interface GetListDKKDPayload {
  search: string
  page: number
  mst: string
  date: Record<'from' | 'to', string>
  query: Record<string, string>
  isActive: number
  phone: number
}

export const getListDKKD = (payload: GetListDKKDPayload) => {
  return ApiClient.get(
    `${dkkd}customer?page=${
      payload.page
    }&search=${payload.search.trim()}&mst=${payload.mst.trim()}&fromDate=${
      payload.date.from
    }&toDate=${payload.date.to}&query=${payload.query}&is_active=${
      payload.isActive
    }&phone=${payload.phone ? 1 : 0}`
  )
}

export const getNghanhNgheChinh = (search: string) => {
  return ApiClient.get(`${dkkd}tenNganhNgheChinh?search=${search}`)
}
export const getLoaiKhoan = (search: string) => {
  return ApiClient.get(`${dkkd}loaiKhoan?search=${search}`)
}
export const getLinhVucKinhTe = (search: string) => {
  return ApiClient.get(`${dkkd}linhVucKinhTe?search=${search}`)
}

export const addAllListDKKDToGroup = (payload: Record<string, any>) => {
  return ApiClient.post(`${dkkd}transferAllDKKD`, payload)
}

const BASE_URL_DEV = ENVIRONMENT.BASE_URL

//export excel file - limit customer
export const handleExportExcelDKKD = (
  payload: GetListDKKDPayload,
  params?: any
) => {
  const data = axios.get(
    `${BASE_URL_DEV}${dkkd}exportExcel?page=${
      payload.page
    }&search=${payload.search.trim()}&mst=${payload.mst.trim()}&fromDate=${
      payload.date.from
    }&toDate=${payload.date.to}&query=${payload.query}&is_active=${
      payload.isActive
    }&phone=${payload.phone ? 1 : 0}`,
    { responseType: 'blob', headers: { 'Content-Type': null }, params }
  )
  return data
}

//export excel file - full customer
// export const handleExportExcelDKKD = (payload: GetListDKKDPayload) => {
//   return ApiClient.get(
//     `${dkkd}exportCustomerUseBull?page=${
//       payload.page
//     }&search=${payload.search.trim()}&mst=${payload.mst.trim()}&fromDate=${
//       payload.date.from
//     }&toDate=${payload.date.to}&query=${payload.query}&is_active=${
//       payload.isActive
//     }&phone=${payload.phone ? 1 : 0}`
//   )
// }
