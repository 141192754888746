import I18n from './i18n/translation'
function strings() {
  return {
    account: I18n.t('account'),
    phone: I18n.t('phone'),
    password: I18n.t('password'),
    login: I18n.t('login'),
    partner: I18n.t('partner'),
    select_all: I18n.t('select_all'),
    dialog_error: I18n.t('dialog.error'),
    dialog_cancel: I18n.t('dialog.cancel'),
    dialog_warning: I18n.t('dialog.warning'),
    dialog_info: I18n.t('dialog.info'),
    dialog_loading: I18n.t('dialog.loading'),
    dialog_select: I18n.t('dialog.select'),
    dialog_success: I18n.t('dialog.success'),
    created_at: I18n.t('created_at'),
    time_minus_hour_ago: I18n.t('time_minus.hour_ago'),
    time_minus_minute_ago: I18n.t('time_minus.minute_ago'),
    time_minus_just_now: I18n.t('time_minus.just_now'),
    time_minus_day_ago: I18n.t('time_minus.day_ago'),
    fail_request: I18n.t('fail_request'),
    error_network: I18n.t('error_network'),
    please_enter_phone: I18n.t('please_enter.phone'),
    please_enter_pass: I18n.t('please_enter.pass'),
    please_select_category_name: I18n.t('please_select.category_name'),
    payment_info: I18n.t('payment.info'),
    payment_method: I18n.t('payment.method'),
    search_category: I18n.t('search.category'),
    search_product: I18n.t('search.product'),
    search_order: I18n.t('search.order'),
    search_campaign_name: I18n.t('search.campaign_name'),
    action_create: I18n.t('action.create'),
    action_remove: I18n.t('action.remove'),
    action_edit: I18n.t('action.edit'),
    action_export: I18n.t('action.export'),
    action_import: I18n.t('action.import'),
    action_update: I18n.t('action.update'),
    action_back: I18n.t('action.back'),
    action_save: I18n.t('action.save'),
    action_create_campaign: I18n.t('action.create_campaign'),
    action_source_setting: I18n.t('action.source_setting'),
    action_create_keyword: I18n.t('action.create_keyword'),
    action_create_group: I18n.t('action.create_group'),
    action_create_page: I18n.t('action.create_page'),
    action_create_post: I18n.t('action.create_post'),
    action_next: I18n.t('action.next'),
    action_turn_on: I18n.t('action.turn_on'),
    action_turn_off: I18n.t('action.turn_off'),
    action_update_tokens: I18n.t('action.update_tokens'),
    action_turn_on_activity: I18n.t('action.turn_on_activity'),
    action_turn_off_activity: I18n.t('action.turn_off_activity'),
    action_export_excel_file: I18n.t('action.export_excel_file'),
    action_select_all: I18n.t('action.select_all'),
    action_add_customer_group: I18n.t('action.add_customer_group'),
    confirm_remove_campaign: I18n.t('confirm.remove_campaign'),
    confirm_remove_keyword: I18n.t('confirm.remove_keyword'),
    status: I18n.t('status'),
    active: I18n.t('active'),
    un_active: I18n.t('un_active'),
    success: I18n.t('success'),
    notification: I18n.t('notification'),
    enter: I18n.t('enter'),
    title_header_customer_resources: I18n.t('title_header.customer_resources'),
    title_header_customer_data: I18n.t('title_header.customer_data'),
    title_header_customer_resources_facebook: I18n.t(
      'title_header.customer_resources_facebook'
    ),
    title_header_data_google_map: I18n.t('title_header.data_google_map'),
    title_header_facebook: I18n.t('title_header.facebook'),
    title_header_google: I18n.t('title_header.google'),
    title_header_set_of_customers: I18n.t('title_header.set_of_customers'),
    title_header_business_registration: I18n.t(
      'title_header.business_registration'
    ),
    title_header_create_campaign: I18n.t('title_header.create_campaign'),
    title_header_source_list: I18n.t('title_header.source_list'),
    title_header_post_list: I18n.t('title_header.post_list'),
    title_header_comment_list: I18n.t('title_header.comment_list'),
    title_header_customer_group: I18n.t('title_header.customer_group'),
    title_modal_add_source: I18n.t('title_modal.add_source'),
    title_modal_keyword_management: I18n.t('title_modal.keyword_management'),
    title_modal_select_group: I18n.t('title_modal.select_group'),
    title_modal_add_keyword: I18n.t('title_modal.add_keyword'),
    title_modal_edit_keyword: I18n.t('title_modal.edit_keyword'),
    title_modal_convert_facebook: I18n.t('title_modal.convert_facebook'),
    title_modal_add_customer_to_group: I18n.t(
      'title_modal.add_customer_to_group'
    ),
    customer_data_campaign: I18n.t('customer_data_campaign'),
    filter_phone: I18n.t('filter_phone'),
    filter_member_customer: I18n.t('filter_member_customer'),
    filter_member_post: I18n.t('filter_member_post'),
    filter_member_ads: I18n.t('filter_member_ads'),
    just_take_member_group: I18n.t('just_take_member_group'),
    validate_get_data_number_day: I18n.t('validate.get_data_number_day'),
    validate_limit_number_of_day: I18n.t('validate.limit_number_of_day'),
    placeholder_get_data_number_day: I18n.t('placeholder.get_data_number_day'),
    placeholder_select_customer_group: I18n.t(
      'placeholder.select_customer_group'
    ),
    placeholder_descriptions: I18n.t('placeholder.descriptions'),
    placeholder_enter_customer_group_name: I18n.t(
      'placeholder.enter_customer_group_name'
    ),
    label_get_data_number_day: I18n.t('label.get_data_number_day'),
    label_customer_group: I18n.t('label.customer_group'),
    label_descriptions: I18n.t('label.descriptions'),
    label_customer_group_name: I18n.t('label.customer_group_name'),
    options_group: I18n.t('options.group'),
    options_page: I18n.t('options.page'),
    options_post: I18n.t('options.post'),
    options_advertisement: I18n.t('options.advertisement'),
    options_filter_by_group: I18n.t('options.filter_by_group'),
    options_enter_group_path: I18n.t('options.enter_group_path'),
    options_create_new_customer_group: I18n.t(
      'options.create_new_customer_group'
    ),
    options_group_exists: I18n.t('options.group_exists'),
    define_status_pending: I18n.t('define_status.pending'),
    define_status_success: I18n.t('define_status.success'),
    define_status_failed: I18n.t('define_status.failed'),
    tokens_remaining: I18n.t('tokens_remaining'),
    update_tokens: I18n.t('update_tokens'),
    click_or_drag_the_token_file_here: I18n.t(
      'click_or_drag_the_token_file_here'
    ),
    process_details: I18n.t('process_details'),
    rules_error_message_please_enter_the_group_name: I18n.t(
      'rules_error_message.please_enter_the_group_name'
    ),
    rules_error_message_choose_a_customer_group: I18n.t(
      'rules_error_message.choose_a_customer_group'
    ),
    message_status_change_successful: I18n.t(
      'message.status_change_successful'
    ),
    message_request_has_been_received: I18n.t(
      'message.request_has_been_received'
    ),
    message_please_wait_a_few_minutes: I18n.t(
      'message.please_wait_a_few_minutes'
    ),
    message_an_error_occurred: I18n.t('message.an_error_occurred'),
    message_please_try_again: I18n.t('message.please_try_again'),
    group: I18n.t('group'),
    page: I18n.t('page'),
    comment: I18n.t('comment'),
    reaction: I18n.t('reaction'),
    total_tokens: I18n.t('total_tokens'),
    token_is_activity: I18n.t('token_is_activity'),
    token_not_activity: I18n.t('token_not_activity'),
  }
}
export default strings
