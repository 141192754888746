import {
  Button,
  Checkbox,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import R from 'assets'
import { ButtonFixed } from 'common/components/Buttons'
import { TooltipCustom } from 'common/components/Tooltip/Tooltip'
import AddCustomerToGroupModal from 'features/customer-resources/components/AddCustomerToGroupModal'
import { RowStyle, TableAntdStyle } from 'features/styles/Styles'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  DF_SELECT_MEMBER,
  optionSelect,
  SELECT_MEMBER_TYPE,
} from 'utils/constants'
import {
  clearDataCustomer,
  exportComments,
  exportMembers,
  getListAllMemberCommentAds,
  getListAllMemberCommentPost,
  getListFacebookPost,
} from '../FacebookApi'
import ButtonSetting from 'common/components/Buttons/ButtonSetting'
import { formatPhoneNumber } from 'utils/funcHelper'
interface Post {
  data: Record<string, any>[]
  page: number
  total: number
  countComments?: number
}

interface recordTableProps {
  created_time: string
  group_id: number
  link: string
  message: []
  page_name: null
  phone: string
  phone_comment: []
  status: string
  user_id: string
  user_name: string
}

interface CustomerDataProps {
  setShowAddSourceModal: (value: boolean) => void
  setActiveKey: (value: string) => void
}

const CustomerData: FC<CustomerDataProps> = ({
  setShowAddSourceModal,
  setActiveKey,
}) => {
  const params: Record<string, string> = useParams()

  const { id } = params
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [selectedRowListID, setSelectedRowListID] = useState<any>([])

  const [paging, setPaging] = useState<Record<string, number>>({
    total: 0,
    page: 1,
    limit: 12,
  })
  const [valueSelect, setValueSelect] = useState<number>(
    SELECT_MEMBER_TYPE.POST
  )

  const [transfererData, setTransfererData] = useState<boolean>(false)
  const [
    visibleModalTransferData,
    setVisibleModalTransferData,
  ] = useState<boolean>(false)
  const [
    isOnlyChooseCustomerWithPhone,
    setIsOnlyChooseCustomerWithPhone,
  ] = useState<boolean>(false)

  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const [post, setPost] = useState<Post>({
    data: [],
    page: 1,
    total: 0,
    countComments: 0,
  })

  const RenderContentNews = (value: []) => {
    let res = value?.filter((item: string) => item).join('')
    return <TooltipCustom content={res ? res : '--'} title={res} />
  }

  const columns: ColumnsType<Record<string, any>> = [
    {
      width: '10%',
      title: 'Tên người dùng cmt',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (name: string, record) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
          href={`https://www.facebook.com/${record?.user_id}`}
        >
          {`${name}`}
        </a>
      ),
    },
    {
      align: 'center',
      title: 'UID',
      dataIndex: 'user_id',
    },
    {
      align: 'center',
      title: 'FB ID',
      dataIndex: 'phone',
      key: '',
      render: (phone: string) => (phone ? formatPhoneNumber(phone) : '--'),
    },
    {
      width: '20%',
      title: 'Link bài viết',
      dataIndex: 'link',
      key: 'link',
      render: (link: string) => (
        <a target="_blank" rel="noopener noreferrer" href={`${link}`}>
          {`${link}`}
        </a>
      ),
    },
    {
      align: 'center',
      title: 'Nội dung cmt',
      dataIndex: 'message',
      key: 'message',
      width: '20%',
      render: RenderContentNews,
    },
    {
      title: 'Nguồn',
      dataIndex: 'page_name',
      key: 'page_name',
      align: 'center',
      render: (page_name: string) => (page_name ? page_name : '--'),
    },
    {
      title: 'Thời gian đăng',
      dataIndex: 'created_ads',
      key: 'created_ads',
      align: 'center',
      width: '120px',
      render: (created_at: string) => moment(created_at).format('DD/MM/YYYY'),
    },
  ]

  const handleClearData = async () => {
    let userIds = selectedRowListID?.map((element: any) => element?.user_id)
    const payload = {
      group_id: selectedRowListID?.length ? '' : post?.data[0]?.group_id,
      userIds,
    }
    try {
      const clearDataResponse = await clearDataCustomer(payload)
      message.success(clearDataResponse.msg)
      getPostData()
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getPostData = async () => {
    setLoadingTable(true)
    const payload = {
      group_id: id,
      page: post.page,
      phone: isOnlyChooseCustomerWithPhone ? 1 : 0,
    }
    try {
      let listPostResponse: any
      if (valueSelect === SELECT_MEMBER_TYPE.POST) {
        listPostResponse = await getListAllMemberCommentPost(payload)
      } else if (valueSelect === SELECT_MEMBER_TYPE.ADS) {
        listPostResponse = await getListAllMemberCommentAds(payload)
      } else {
        listPostResponse = await getListFacebookPost(payload)
      }

      const addedKeyListCustomers = listPostResponse.data?.rows?.map(
        (customer: any, index: number) => ({
          ...customer,
          key: customer?.user_id + `_${index}_${post.page}`,
        })
      )

      if (isSelectAll) {
        setSelectedRowListID(
          listPostResponse.data?.rows?.map((row: any, index: any) => ({
            key: row?.user_id + `_${index}_${post.page}`,
            id: row?.id,
            name: row.user_name,
            phone: row.phone,
            type: row.type,
            user_id: row?.user_id,
          }))
        )
      }

      setPaging({
        total: listPostResponse?.paging.totalItem,
        current: listPostResponse?.paging.page,
        pageSize: listPostResponse?.paging.limit,
      })
      setPost(prevCustomer => ({
        ...prevCustomer,
        data: addedKeyListCustomers,
        total: listPostResponse?.paging?.totalItem,
        countComments: listPostResponse?.data?.count,
      }))
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const changePostsPage = (page: number) => {
    setPost(prePost => ({ ...prePost, page: page }))
  }

  const transferData = async () => {
    setVisibleModalTransferData(true)
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      if (isSelectAll && selectedRowKeys?.length === paging?.pageSize) {
        setIsSelectAll(true)
      } else {
        setIsSelectAll(false)
      }
      // setSelectedRowListID(
      //   selectedRows?.map((row: any) => ({
      //     id: row.id,
      //     name: row.user_name,
      //     phone: row.phone,
      //     type: row.type,
      //     user_id: row?.user_id,
      //     key: row.key,
      //   }))
      // )
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: any[],
      changeRows: any[]
    ) => {
      if (!selected) {
        // set choseProduct when exits changeRows
        setSelectedRowListID((prev: any) =>
          prev.filter(
            (row: any) => !changeRows.some(item => row.key === item.key)
          )
        )
      } else {
        const newSelectedRowListID = [...selectedRowListID]
        changeRows.forEach(row => {
          if (!newSelectedRowListID.some(item => item.key === row.key)) {
            newSelectedRowListID.push(row)
          }
        })
        setSelectedRowListID(newSelectedRowListID)
      }
    },
    onSelect: (record: any, selected: any) => {
      if (!selected) {
        setSelectedRowListID((prev: any) =>
          prev.filter((row: any) => row.key !== record.key)
        )
      } else {
        setSelectedRowListID((prev: any) => [...prev, record])
      }
    },
    preserveSelectedRowKeys: true,
  }

  //Chọn tất cả
  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll)
    if (!isSelectAll) {
      setSelectedRowListID(
        post.data?.map((row: any, index: any) => ({
          id: row.id,
          name: row.user_name,
          phone: row.phone,
          type: row.type,
          user_id: row?.user_id,
          key: row.key,
        }))
      )
    } else {
      setSelectedRowListID([])
    }
  }

  //lọc theo số đt
  const handleFilterPhone = useCallback(() => {
    setIsOnlyChooseCustomerWithPhone(!isOnlyChooseCustomerWithPhone)
    setPost({
      ...post,
      page: 1,
    })
  }, [isOnlyChooseCustomerWithPhone])

  const handleChangeSelect = (value: number | string) => {
    setValueSelect(Number(value))
    setSelectedRowListID([])
  }

  useEffect(() => {
    getPostData()
  }, [post.page, valueSelect, isOnlyChooseCustomerWithPhone])

  const handleExportExcel = async () => {
    const payload = {
      group_id: id,
      phone: isOnlyChooseCustomerWithPhone ? 1 : 0,
      ids: selectedRowListID.map((row: { id: any }) => row.id).join(','),
    }
    try {
      if (valueSelect === SELECT_MEMBER_TYPE.CUSTOMER) {
        const res = await exportMembers(payload)
        window.open(res.data.file, '_blank')
      }
      if (valueSelect === SELECT_MEMBER_TYPE.POST) {
        const res = await exportComments(payload)
        window.open(res.data.file, '_blank')
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <>
      <RowStyle>
        <Row style={{ display: 'flex', flexDirection: 'column' }}>
          <p>{paging?.total} Khách hàng</p>
          <p>Dữ liệu tổng hợp từ nhóm khách hàng của bạn</p>
        </Row>
        <div>
          <Space>
            <ButtonSetting
              text={R.strings().action_source_setting}
              onClickButton={() => {
                setActiveKey('2')
                setShowAddSourceModal(true)
              }}
            />
            <ButtonFixed
              style={{
                backgroundColor: '#449d44',
                border: 'none',
                color: 'white',
                fontSize: 16,
              }}
              text="Export excel"
              onClickButton={handleExportExcel}
            />
            {/* <Popconfirm
              placement="bottomRight"
              title="Bạn có thật sự muốn clear dữ liệu?"
              okText="Xóa"
              cancelText="Hủy"
              onConfirm={handleClearData}
              disabled={false}
            >
              <Button
                style={{
                  backgroundColor: 'red',
                  border: 'none',
                  color: 'white',
                  fontSize: 16,
                }}
              >
                Clear dữ liệu
              </Button>
            </Popconfirm> */}

            <ButtonFixed
              text="Chuyển dữ liệu"
              style={{
                backgroundColor: '#0bcb0b',
                border: 'none',
                color: 'white',
                fontWeight: '500',
              }}
              disable={!selectedRowListID?.length}
              onClickButton={transferData}
            />
          </Space>
        </div>
      </RowStyle>
      <RowStyle>
        <div>
          <Checkbox checked={isSelectAll} onChange={handleSelectAll}>
            {R.strings().select_all}
          </Checkbox>
        </div>

        <Row align="middle">
          <Checkbox
            style={{ marginRight: 5 }}
            checked={isOnlyChooseCustomerWithPhone}
            onChange={handleFilterPhone}
          >
            {R.strings().filter_phone}
          </Checkbox>

          <Select
            value={DF_SELECT_MEMBER[valueSelect]?.title}
            onChange={handleChangeSelect}
            style={{ width: 200 }}
            options={optionSelect}
          />
        </Row>
      </RowStyle>
      <TableAntdStyle
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
        }}
        dataSource={post?.data}
        loading={loadingTable}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRowListID?.map(
            ({ key }: { key: string }) => key
          ),
        }}
        rowKey={(record: any, index) => {
          return record.key
        }}
        columns={columns}
        pagination={false}
      />
      <Row justify="end">
        {post?.total > 0 && (
          <Pagination
            style={{ marginTop: '10px' }}
            total={post.total}
            pageSize={12}
            showSizeChanger={false}
            onChange={changePostsPage}
          ></Pagination>
        )}
      </Row>
      {visibleModalTransferData && (
        <AddCustomerToGroupModal
          transferAll={isSelectAll}
          filter={valueSelect}
          group_id={id}
          setTransferedData={setTransfererData}
          show={visibleModalTransferData}
          choosenConvertedFBList={selectedRowListID}
          setShowAddCustomerToGroupModal={setVisibleModalTransferData}
        />
      )}
    </>
  )
}
export default CustomerData
