const images = {
  ic_logo: require('./images/ic_logo.png').default,
  ic_logo2: require('./images/ic_logo2.png').default,
  img_background: require('./images/img_background.jpg').default,
  img_background_login: require('./images/img_background_login.jpg').default,
  img_background_login2: require('./images/img_background_login2.jpg').default,
  img_logo: require('./images/img_logo.png').default,
  no_data: require('./images/tool-no-data.png').default,
}
export default images
