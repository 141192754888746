import { ApiClient } from 'services/ApiService'

//API chiến dịch
export const getListCampaign = (payload: {
  search: string
  page?: number
  limit?: number
}) => ApiClient.get('/tool/listGroup', payload)

export const getDetailCampaign = (payload: {
  group_id: number
  type_group: string
  phone: 1 | 0
}) => ApiClient.get('/tool/groupDetail', payload)

export const createCampaign = (payload: { name: string; type: number }) =>
  ApiClient.post('/tool/createGroup', payload)

export const updateCampaign = (payload: {
  group_id: number
  name: string
  type: number
  is_active: number
}) => ApiClient.post('/tool/updateGroup', payload)

export const deleteCampaign = (payload: { group_id: number }) =>
  ApiClient.post('/tool/deleteGroup', payload)

export const getListPost = (payload: {
  type?: number
  sub_group_id: number
  page?: number
}) => ApiClient.get('/tool/listPost', payload)

export const deleteLinkPost = (payload: { link_id: number; type: string }) =>
  ApiClient.post('/tool/deleteLink', payload)

export const changeLinkPost = (payload: {
  link_id: number
  is_active: number
}) => ApiClient.post('/tool/isActiveLink', payload)

//API keyword
export const getListKeyword = (payload: { search: string; page?: number }) =>
  ApiClient.get('/tool/listKeyword', payload)

export const createKeyword = (payload: { content: string }) =>
  ApiClient.post('/tool/createKeyword', payload)

export const updateKeyword = (payload: {
  keyword_id: number
  content: string
  is_active: number
}) => ApiClient.post('/tool/updateKeyword', payload)

export const deleteKeyword = (payload: { keyword_id: number }) =>
  ApiClient.post('/tool/deleteKeyword', payload)

//API lĩnh vực
export const getFacebookBranch = (limit?: number) =>
  ApiClient.get('/face/branch', limit)

export const getListFacebookPost = (
  payload: Record<string, string | number | any>
) =>
  ApiClient.get(
    `face/findAllCustomer?group_id=${payload.group_id}&page=${payload.page}&phone=${payload.phone}&type_group=${payload.type}&all=${payload.all}`
  )

export const getListAllMemberCommentPost = (
  payload: Record<string, string | number | any>
) =>
  ApiClient.get(
    `face/findAllMemberCommtentPost?group_id=${payload.group_id}&page=${payload.page}&phone=${payload.phone}&type_group=&all=${payload.all}`
  )

export const exportComments = (
  payload: Record<string, string | number | any>
) =>
  ApiClient.get(
    `face/exportCommentsPost?group_id=${payload.group_id}&phone=${payload.phone}&type_group=&ids=${payload.ids}`
  )

export const exportMembers = (payload: Record<string, string | number | any>) =>
  ApiClient.get(
    `face/exportReactionPost?all=undefined&group_id=${payload.group_id}&phone=${payload.phone}&type_group=undefined&ids=${payload.ids}`
  )

export const getListAllMemberCommentAds = (
  payload: Record<string, string | number | any>
) =>
  ApiClient.get(
    `face/findAllMemberCommentAds?group_id=${payload.group_id}&page=${payload.page}&phone=${payload.phone}&type_group=&all=${payload.all}`
  )

// http://3.1.13.10:9019/face/findAllMemberCommentAds?group_id=190&page=1&phone=0&type_group=

export const getListFacebookCustomer = (
  payload: Record<string, string | number>
) =>
  ApiClient.get(
    `tool/listCustomer?page_id=${payload.page_id}&group_id=${payload.group_id}&page=${payload.page}&phone=${payload.phone}&type=${payload.type}`
  )
//API thiết lập nguồn
export const createSource = (payload: any) =>
  ApiClient.post('/tool/createSourceCustomer', payload)

export const deleteSource = (payload: { sub_group_id: number }) =>
  ApiClient.post('/tool/deleteSubGroup', payload)

export const changeActiveSource = (payload: {
  sub_group_id: number
  is_active: number
}) => ApiClient.post('/tool/updateSubGroup', payload)

export const updateKeywordSource = (payload: {
  sub_group_id: number
  list_keyword: Array<object>
}) => ApiClient.post('/tool/updateKeywordSubGroup', payload)

//API nhóm facebook
export const getFacebookCategory = (payload: { search: string }) =>
  ApiClient.get('/face/category', payload)

export const convertfb = (payload: Record<'links', string[]>) =>
  ApiClient.post('/face/convertfb', payload)

export const getListGroupCustomer = (
  payload: Record<'search' | 'page' | 'limit', string | number>
) =>
  ApiClient.get(
    `/tool/listGroupCustomer?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`
  )
export const getListGroupCustomerVer2 = (groupName: string) =>
  ApiClient.get(`/tool/listGroupCustomer?search=${groupName}&page=1&limit=-1`)

export const getDetailListGroupCustomer = (
  payload: Record<'group_data_id' | 'page', string | number>
) =>
  ApiClient.get(
    `/tool/groupCustomerDetail?group_data_id=${payload.group_data_id}&page=${payload.page}`
  )

interface TransferCustomerDataType {
  list_customer_id: number[]
  group_data_id?: number
  group_data_name?: string
  group_data_description?: string
  page_id?: string
  group_id?: number
}

interface CleanDataCustomerProps {
  group_id?: string
  userIds?: Array<string>
}
export const transferCustomerData = (payload: TransferCustomerDataType) =>
  ApiClient.post('/tool/transferCustomerData', payload)

export const exportExcelDetailOfGroupCustomer = (group_data_id: string) =>
  ApiClient.get(`/tool/groupCustomerDetailExcel?group_data_id=${group_data_id}`)

export const deleteGroupCustomerAPI = (group_data_id: string) =>
  ApiClient.post(`/tool/deleteGroupCustomer`, { group_data_id })

export const clearData = (fb_post_id: string) =>
  ApiClient.post(`/tool/clearDataCustomer`, { fb_post_id })

export const clearDataCustomer = (payload: CleanDataCustomerProps) =>
  ApiClient.post(`/face/clearDataCustomer`, payload)
