import React, { ReactNode, useEffect, useState } from 'react'
import {
  CaretRightOutlined,
  InboxOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Collapse, message, Skeleton, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'

import { getUserInfoAction } from 'features/auth/AuthSlice'
import { createFormData } from 'utils/createFormData'
import { ButtonAdd } from 'common/components/Buttons'
import { numberFormat } from 'utils/funcHelper'
import { ApiClient } from 'services/ApiService'
import R from 'assets'

const { Panel } = Collapse
const { Dragger } = Upload

const StyleExpandMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyleTokenCount = styled.div`
  margin-left: 10px;
  font-weight: 700;
`

const StyleDragger = styled(Dragger)`
  &&& {
    .ant-upload {
      padding: 0px;
    }

    .ant-upload-text,
    .ant-upload-drag-icon {
      font-size: 12px;
      margin: 8px 0px;
    }

    margin: 10px;
    width: 90%;
  }
`

const StyleButtonSpace = styled.div`
  margin: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`
const urlGetInformationCrawl =
  'https://dev.fbcrawlerapi.ezsale.vn/api/v1/report'

const uploadTokenFile = (payload: any) =>
  ApiClient.post('/token/uploads', payload)

const acceptFileFormat = '.txt'

function MenuExpand() {
  const dispatch = useDispatch()

  const userInfo = useSelector(
    (redux: Record<string, any>) => redux.authReducer.userInfo
  )

  const [tokenFile, setTokenFile] = useState<File | undefined>(undefined)

  const [
    loadingInformationCrawl,
    setLoadingInformationCrawl,
  ] = useState<boolean>(false)

  const [informationCrawl, setInformationCrawl] = useState<
    Record<string, number | null>
  >({
    [R.strings().group]: null,
    [R.strings().page]: null,
    [R.strings().comment]: null,
    [R.strings().reaction]: null,
    [R.strings().total_tokens]: null,
    [R.strings().token_is_activity]: null,
    [R.strings().token_not_activity]: null,
  })

  const propsDragger: Record<string, any> = {
    name: 'file',
    multiple: false,
    fileList: tokenFile && [tokenFile],
    maxCount: 1,
    accept: acceptFileFormat,
    onRemove: () => setTokenFile(undefined),
    customRequest: async (options: Record<string, any>) => {
      const { onSuccess, file } = options
      setTokenFile(file)
      onSuccess('ok')
    },
  }

  const handleTokenUpload = async () => {
    try {
      const formData = createFormData({ file: tokenFile })
      await uploadTokenFile(formData)
      message.success(R.strings().dialog_success)
      setTokenFile(undefined)
      dispatch(getUserInfoAction())
    } catch (error) {
      console.log('Error upload token file: ', { error })
    }
  }

  const getInformationCrawl = async () => {
    try {
      setLoadingInformationCrawl(true)
      const { data } = await axios.get(urlGetInformationCrawl)

      setInformationCrawl({
        [R.strings().group]: data.group_news_feed,
        [R.strings().page]: data.page_news_feed,
        [R.strings().comment]: data.member_comment,
        [R.strings().reaction]: data.member_reaction,
        [R.strings().total_tokens]: data.tokens.total_token,
        [R.strings().token_is_activity]: data.tokens.fb_token.token_eq_1,
        [R.strings().token_not_activity]: data.tokens.fb_token.token_gt_1,
      })
    } catch (error) {
      console.log('Error getInformationCraw: ', { error })
    } finally {
      setLoadingInformationCrawl(false)
    }
  }

  useEffect(() => {
    getInformationCrawl()
  }, [])

  const renderItemProcessDetail = (): ReactNode => {
    return Object.entries(informationCrawl).map(el => (
      <li>
        {el[0]}: <b>{numberFormat(el[1])}</b>
      </li>
    ))
  }

  return (
    <>
      {userInfo &&
        Object.prototype.hasOwnProperty.call(userInfo, 'totalToken') && (
          <StyleExpandMenu>
            <StyleTokenCount>
              {R.strings().tokens_remaining + ': ' + userInfo.totalToken}
            </StyleTokenCount>

            <hr />

            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                header={R.strings().process_details}
                key="information-crawl"
                className="site-collapse-custom-panel"
                extra={
                  <ReloadOutlined
                    onClick={event => {
                      event.stopPropagation()
                      getInformationCrawl()
                    }}
                  />
                }
              >
                {loadingInformationCrawl ? (
                  <Skeleton active />
                ) : (
                  <ul style={{ paddingLeft: '16px' }}>
                    {renderItemProcessDetail()}
                  </ul>
                )}
              </Panel>

              <Panel
                header={R.strings().update_tokens}
                key="update-token"
                className="site-collapse-custom-panel"
              >
                <StyleDragger {...propsDragger}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {R.strings().click_or_drag_the_token_file_here}
                  </p>
                </StyleDragger>

                <StyleButtonSpace>
                  <ButtonAdd
                    disabled={!tokenFile}
                    text={R.strings().action_update_tokens}
                    onClickButton={handleTokenUpload}
                  />
                </StyleButtonSpace>
              </Panel>
            </Collapse>
          </StyleExpandMenu>
        )}
    </>
  )
}

export default MenuExpand
